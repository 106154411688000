import { useEffect, useState } from "react";
import { Col, FormControl } from "react-bootstrap";
import { MdLocationCity } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import FormSubmit from "../shared/submit";
import { loadAllState, LoadAllState } from "../../utils/state";
import CustomModal from "../shared/modal";

// if (navigator.geolocation) {
//   navigator.geolocation.watchPosition(showPosition);
// } else {
//   x.innerHTML = "Geolocation is not supported by this browser.";
// }

const Location = () => {
  const [show, setShow] = useState(false);

  const indexData = useSelector((s) => s.index);
  const dispatch = useDispatch();
  const { zipData } = indexData;
  useEffect(() => {
    loadAllState(dispatch);
  }, []);

  const HandleSubmit = (data) => {
    if (!data) return;
    loadAllState(dispatch);
    setShow(false);
  };

  console.log(indexData);

  return (
    <>
      <div
        className="d-flex align-items-center"
        role="button"
        onClick={(_) => setShow(true)}
      >
        <HiOutlineLocationMarker className="me-2" size={20} />
        <div>
          <p className="mb-0 font-tiny">Deliver to,</p>
          <p className="font-sm fw-600 mb-0">{zipData?.city}</p>
        </div>
      </div>
      <CustomModal
        size="sm"
        // className="light-modal"
        show={show}
        onHide={(_) => setShow(false)}
      >
        <div className="d-flex mb-3">
          <MdLocationCity className="me-2 text-grey" size={16} />
          <span className="font-md">
            Currently at{" "}
            <span className="fw-600 text-secondary">
              {zipData?.city}, {zipData?.stateCode}
            </span>
          </span>
        </div>
        <FormSubmit callback={HandleSubmit} url="/state">
          <div className="flex-between">
            <FormControl
              defaultValue={zipData?.zipcode}
              name="zipcode"
              autoFocus={show}
              focus={show}
              className="shadow-none font-md border-secondary"
              placeholder="Us Zipcode"
            />
            <button className="btn font-md btn-secondary ms-2 shadow-none">
              Apply
            </button>
          </div>
        </FormSubmit>
        <p className="mt-3 font-sm text-grey">
          Delivery options and delivery speeds may vary for different locations
        </p>
      </CustomModal>
    </>
  );
};

export default Location;
