import { Col, Container, Row } from "react-bootstrap";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { Logo } from "../shared/common";
const Footer = () => {
  return (
    <footer>
      <div className="bg-light text-white text-center py-3">
        <Container className="home-container">
          <Row className="home-container justify-content-between">
            <Col sm="auto">
              <Row>
                {social.map((s) => (
                  <Col>
                    <a href={s.href} className="fs-4" target="_blank">
                      {s.icon}
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col sm="auto">
              <a href="" className="text-dark font-lg">
                Scoll To Top
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg- border-top border-white border-2 py-5">
        <Container className="home-container">
          <Row className="mb-5 align-items-end">
            <Col sm="2">
              <div className="logo px-3">
                <Link href="/">
                  <a href="/" className="navbar-brand">
                    <Logo />
                  </a>
                </Link>
              </div>
            </Col>
            <Col sm="auto">
              <Row className="align-items-center">
                {quicklinks.map((s, i) => (
                  <Col sm="auto">
                    <Link href={s.href}>
                      <a
                        href={s.href}
                        className="text-dark font-lg d-flex align-items-center"
                      >
                        <span className="me-3 pb-2 fw-bold">
                          {i === 0 ? "" : "."}
                        </span>
                        {s.title}
                      </a>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <div className="py-4 border-top border-2 border-light">
            <h3 className="fw-600  fs-6">About</h3>
            <p className="font-e-lg">
              A reseller of new and open box items, aiming to provide delivery
              and installation of product to the customers and provide technical
              assistance on Home improvement, furniture, Indoor, Outdoor,
              Garden, Office. Our technicians will deliver the item and do the
              installation, to make customer life easy and targeting to beat the
              cost in market for the same service
            </p>
          </div>
        </Container>
        <div className="bg-light h-100">
          <p className="text-dark fw-600 font-e-lg text-center p-3">
            Copyright © {new Date().getFullYear()}, Dealsonopenbox LLC . All
            Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const GetLink = (title, href) => ({ title, href });

const quicklinks = [
  GetLink("Tool Rental", "/search?type=tool_rental"),
  GetLink("Classified", "/classified"),
  GetLink("WishList", "/wishlist"),
  GetLink("Cart", "/cart"),
  GetLink("Browse", "/search"),
  GetLink("Sell On Dealsonopenbox", "https://members.dealsonopenbox.com"),
];

const social = [
  {
    icon: <FaFacebookF className="text-primary" />, //<FaFacebookSquare />,
    href: "/",
  },
  {
    icon: <AiOutlineInstagram className="text-danger" />,
    href: "/",
  },
  {
    icon: <FaPinterestP className="text-danger" />,
    href: "/",
  },
];
