import Head from "next/head";

const GlobalHead = (props) => {
  const title = props.title || "DealsOnOpenBox";
  const description =
    props.description ||
    "A reseller of new and open box items, aiming to provide delivery and installation of product to the customers and provide technical assistance on Home improvement, furniture, Indoor, Outdoor, Garden, Office. Our technicians will deliver the item and do the installation, to make customer life easy and targeting to beat the cost in market for the same service";

  return (
    <Head>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Deals,Openbox,DealsOnOpenBox,Reseller,English,Sell Your Product,40 miles,Free Delivery,CashOnDelivery,PayOnDelivery,Local Pickup,100% Trusted,Resellers Club"
      />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 days" />
      <title>{title}</title>
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      {/* <link rel="manifest" href="/manifest.json" /> */}
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta charSet="utf-8" />
      <link rel="preconnect" href="https://dealsonopenbox.azureedge.net" />
      <link rel="dns-prefetch" href="https://dealsonopenbox.azureedge.net" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="import" href="https://secure.dealsonopenbox.com/" />
    </Head>
  );
};

export default GlobalHead;
