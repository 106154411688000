import { SET_AUTH_DATA, SET_CART_DATA, SET_INDEX_DATA } from "../redux/types";
import { GET } from "./requests";

export const loadAllState = async (dispatch) => {
  initHome(dispatch);
  initAuth(dispatch);
  initCart(dispatch);
};

export const initHome = async (dispatch) => {
  dispatch({
    type: SET_INDEX_DATA,
    data: await GET("/"),
  });
};

export const initAuth = async (dispatch) => {
  dispatch({
    type: SET_AUTH_DATA,
    data: await GET("/auth"),
  });
};

export const initCart = async (dispatch) => {
  const data = await GET("/cart");
  dispatch({
    type: SET_CART_DATA,
    data,
  });
};
