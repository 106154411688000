import React, { useEffect } from "react";
import GlobalHead from "../shared/head";
import Header from "../header";
import Footer from "../header/footer";
import { useDispatch } from "react-redux";
// import Footer from "../header/footer";
const Home = (props) => {
  const dispatch = useDispatch();
  const { children, className } = props;

  return (
    <div>
      <GlobalHead {...props} />
      <Header />
      <div className={className}>{children}</div>
      <Footer />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-0SLYHFQCX6"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-0SLYHFQCX6');
            `,
        }}
      />
    </div>
  );
};

export default Home;
